import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import loggo_colegio from "../../assets/loggo_colegio.png";
import "./FirstPage.css"
import { getBooks } from '../../services/bookServices';
import { useDispatch } from 'react-redux';
import { booksCopyAction } from '../../stateManagement/actions/booksInfoAction';
// import { booksCatalogoAction, booksCopyAction, booksInfo } from '../../stateManagement/actions/booksInfoAction';
// import { getAuthors, getCountries, getGenres } from '../../services/dataByLanguage';
// import { booksAuthors, booksCountries, booksGenres } from '../../stateManagement/actions/stateActions';
import { recomendedAction } from '../../stateManagement/actions/recommendedAction';
import { changeLanguageAction } from '../../stateManagement/actions/changeLanguageAction';



export default function FirstPage() {

  const dispatch = useDispatch()

  const demo = false

  const charge = async () =>{
    dispatch(changeLanguageAction(1))
    // const [genres, countries, authors] = await Promise.all([
    //     getGenres(1),
    //     getCountries(1),
    //     getAuthors(1),
    // ]
    // )
    const books = await getBooks(1)
    if(books?.length>0){
      // const recommend = books.filter(r => r.isFree===true)
      // dispatch(recomendedAction(recommend))
      if(demo){
          books.sort((x, y) => x.name.localeCompare(y.name))
          const demoBooks = books.slice(0,100)
          // dispatch(booksInfo(demoBooks))
          // dispatch(booksCatalogoAction(demoBooks))
          dispatch(booksCopyAction(demoBooks))
          // dispatch(recomendedAction(recommend))
      }
      else {
          // dispatch(booksInfo(books))
          // dispatch(booksCatalogoAction(books))
          dispatch(booksCopyAction(books))
          // dispatch(recomendedAction(recommend))
      }
    }
    // dispatch(booksGenres(genres))
    // dispatch(booksCountries(countries))
    // dispatch(booksAuthors(authors))
  }

  useEffect(()=> {// eslint-disable-next-line react-hooks/exhaustive-deps
    charge();}, [])



  return (
    <div className="container_pg">
      <br/>
      <div>
          

            <img
                      src={loggo_colegio}
                      width="200"
                      height="200"
                      className="img-fluid d-block mx-auto"
                      alt='logoMingga'
                      />
                     


          <h3 id="titulo1_" > <b><i>
           CENTRO EDUCATIVO CATALINA</i></b></h3>
           <br />

            <h5 id="titulo1_">PROGRAMA DE FOMENTO <br />
            DE LA LECTURA Y LA ESCRITURA
             </h5>
          <br />
          <h2 id="titulo1_"> <b>Vamos a leer!! </b></h2>
          <br />

          <h5 id="titulo1_" > <b><i> Para construir entre todos
           <br />
           un mundo más educado y culto</i></b> </h5>
          
          <br />
          <Link         
          className="btn btn-success" id="seguir_1" to="/SecondPage"><b>Seguir </b> 
          </Link>
          <br />
          

      </div>
    </div>
  )
}