import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import rectora from "../../assets/rectora.jpg";
import "./SecondPage.css"
import { getBooks } from '../../services/bookServices';
import { useDispatch } from 'react-redux';
import { booksCopyAction } from '../../stateManagement/actions/booksInfoAction';
// import { booksCatalogoAction, booksCopyAction, booksInfo } from '../../stateManagement/actions/booksInfoAction';
// import { getAuthors, getCountries, getGenres } from '../../services/dataByLanguage';
// import { booksAuthors, booksCountries, booksGenres } from '../../stateManagement/actions/stateActions';
import { recomendedAction } from '../../stateManagement/actions/recommendedAction';
import { changeLanguageAction } from '../../stateManagement/actions/changeLanguageAction';



export default function SecondPage() {

  const dispatch = useDispatch()

  const demo = false

  const charge = async () =>{
    dispatch(changeLanguageAction(1))
    // const [genres, countries, authors] = await Promise.all([
    //     getGenres(1),
    //     getCountries(1),
    //     getAuthors(1),
    // ]
    // )
    const books = await getBooks(1)
    if(books?.length>0){
      // const recommend = books.filter(r => r.isFree===true)
      // dispatch(recomendedAction(recommend))
      if(demo){
          books.sort((x, y) => x.name.localeCompare(y.name))
          const demoBooks = books.slice(0,100)
          // dispatch(booksInfo(demoBooks))
          // dispatch(booksCatalogoAction(demoBooks))
          dispatch(booksCopyAction(demoBooks))
          // dispatch(recomendedAction(recommend))
      }
      else {
          // dispatch(booksInfo(books))
          // dispatch(booksCatalogoAction(books))
          dispatch(booksCopyAction(books))
          // dispatch(recomendedAction(recommend))
      }
    }
    // dispatch(booksGenres(genres))
    // dispatch(booksCountries(countries))
    // dispatch(booksAuthors(authors))
  }

  useEffect(()=> {// eslint-disable-next-line react-hooks/exhaustive-deps
    charge();}, [])



  return (
    <div className="container_pg">
      <br/>
      <div>
          <h4 id="titulo1_"> <b><i>  CENTRO EDUCATIVO CATALINA <br />
             </i></b></h4>

            <img
                      src={rectora}
                      width="180"
                      height="180"
                      className="img-fluid d-block mx-auto"
                      alt='logoMingga'
                      />
                     


          <h5 id="titulo1_" > <b><i> María Patricia Olmos Pino
           <br />
           RECTORA</i></b> </h5>
           <br />


          <h6 id="titulo1_" className="container_form"> Para nuestra Institución Educativa, es una prioridad mejorar el nivel educativo y cultural de todos nuestros estudiantes, porque este es el medio por excelencia para aumentar las competencias de niños y niñas para desempeñarse con éxito en las diferentes actividades académicas y sociales, y para avanzar en la construcción de una sociedad más democrática, pacífica y progresista.
            <br />
            <br />
            Para mí como directora es un gusto poner a disposición de todos nuestros estudiantes un instrumento digital moderno y eficiente con cobertura en nuestras áreas urbanas y rurales, con la esperanza de que sea aprovechado por todas y todos para su mejoramiento educativo y cultural.
          </h6>
          <br />
          
          <h5 id="titulo1_" > <b><i>
          Vamos a leer <br />
          Para construir entre todos <br />
          un mundo donde los nniños y niñas sean más educados y cultos</i></b> </h5>
           <br />


          
          <br />
          <Link         
          className="btn btn-success" id="seguir_1" to="/SignIn"><b>Seguir </b> 
          </Link>
          <br />
          

      </div>
    </div>
  )
}